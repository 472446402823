#Footer {
  min-height: 5rem;
  background: rgba(0, 0, 0, 0.85);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#Footer .scrollToTopBtn {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}