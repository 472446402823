#ContactForm {
  text-align: left;
}

#ContactForm .buttonWrapper {
  margin-top: 1rem;
  text-align: right;
}

#ContactForm .preserveWhiteSpace {
  white-space: pre;
}

#ContactForm .responseMessage {
  margin-right: 1rem;
}

#ContactForm .verticalAlignMiddle {
  vertical-align: middle;
}
