#Technologies {
  text-align: center;
  background-color: #f1ece9;
}

#Technologies .imageWrapper {
  height: 150px;
  display: flex;
  justify-content: center;
}

#Technologies .skillBox {
  min-height: 250px;
}

#Technologies .image {
  width: 100px;
  height: auto;
}