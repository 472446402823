#Projects {
  text-align: center;
}

.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ripped from materialize Card element */

.mCard {
  position: relative;
  margin: 0.5rem 0 1rem 0;
  background-color: #fff;
  -webkit-transition: -webkit-box-shadow .25s;
  transition: -webkit-box-shadow .25s;
  transition: box-shadow .25s;
  transition: box-shadow .25s, -webkit-box-shadow .25s;
  border-radius: 2px;
}

.mCard .mCard-title {
  font-size: 24px;
  font-weight: 300;
}

.mCard .mCard-title.activator {
  cursor: pointer;
}

.mCard.small, .mCard.medium, .mCard.large {
  position: relative;
}

.mCard.small .mCard-image, .mCard.medium .mCard-image, .mCard.large .mCard-image {
  max-height: 60%;
  overflow: hidden;
}

.mCard.small .mCard-image + .mCard-content, .mCard.medium .mCard-image + .mCard-content, .mCard.large .mCard-image + .mCard-content {
  max-height: 40%;
}

.mCard.small .mCard-content, .mCard.medium .mCard-content, .mCard.large .mCard-content {
  max-height: 100%;
  overflow: hidden;
}

.mCard.small .mCard-action, .mCard.medium .mCard-action, .mCard.large .mCard-action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCard.small {
  height: 300px;
}

.mCard.medium {
  height: 400px;
}

.mCard.large {
  height: 500px;
}

.mCard.horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.mCard.horizontal.small .mCard-image, .mCard.horizontal.medium .mCard-image, .mCard.horizontal.large .mCard-image {
  height: 100%;
  max-height: none;
  overflow: visible;
}

.mCard.horizontal.small .mCard-image img, .mCard.horizontal.medium .mCard-image img, .mCard.horizontal.large .mCard-image img {
  height: 100%;
}

.mCard.horizontal .mCard-image {
  max-width: 50%;
}

.mCard.horizontal .mCard-image img {
  border-radius: 2px 0 0 2px;
  max-width: 100%;
  width: auto;
}

.mCard.horizontal .mCard-stacked {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
}

.mCard.horizontal .mCard-stacked .mCard-content {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.mCard.sticky-action .mCard-action {
  z-index: 2;
}

.mCard.sticky-action .mCard-reveal {
  z-index: 1;
  padding-bottom: 64px;
}

.mCard .mCard-image {
  position: relative;
}

.mCard .mCard-image img {
  display: block;
  border-radius: 2px 2px 0 0;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mCard .mCard-image .mCard-title {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  padding: 24px;
}

.mCard .mCard-content {
  padding: 24px;
  border-radius: 0 0 2px 2px;
}

.mCard .mCard-content p {
  margin: 0;
}

.mCard .mCard-content .mCard-title {
  display: block;
  line-height: 32px;
  margin-bottom: 8px;
}

.mCard .mCard-content .mCard-title i {
  line-height: 32px;
}

.mCard .mCard-action {
  background-color: inherit;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
  position: relative;
  padding: 16px 24px;
}

.mCard .mCard-action:last-child {
  border-radius: 0 0 2px 2px;
}

.mCard .mCard-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating) {
  color: #ffab40;
  margin-right: 24px;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
  text-transform: uppercase;
}

.mCard .mCard-action a:not(.btn):not(.btn-large):not(.btn-small):not(.btn-large):not(.btn-floating):hover {
  color: #ffd8a6;
}

.mCard .mCard-reveal {
  padding: 24px;
  position: absolute;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  left: 0;
  top: 100%;
  height: 100%;
  z-index: 3;
  display: none;
}

.mCard .mCard-reveal .mCard-title {
  cursor: pointer;
  display: block;
}

.cardOverride {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}
